
import { defineComponent, onMounted, ref, computed, reactive } from 'vue'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { FormInstance } from 'element-plus';
import { store } from "@/store";
import { onBeforeRouteLeave } from 'vue-router';
import { notification } from '@/store/stateless';
import Validations from '@/core/services/etc/Validations';
import router from '@/router/clean'
import Users from '@/core/services/Callable/Users'

export default defineComponent({
    setup() {
        const loading = ref(false)
        const ruleFormRef = ref<FormInstance>()
        const userForm = ref()
        const firstError = ref('name')

        onMounted(async() => {
            setCurrentPageBreadcrumbs("Edit User", [{ label: "Users", value: "UserLists" }]);

            const id = router.currentRoute.value.query ? parseInt(router.currentRoute.value.params.user_id.toString()) : 0
            await Users.fetchUser({ id })
            userForm.value.focus()
        })

        const submitStore = async (formEl: FormInstance | undefined) => {
            loading.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                if(valid) {
                    const result = await Users.updateUser(user.value)
                    if(result.status === 200) {
                        notification.success('User', 'User has been updated successfully')
                        Users.setUserDefault()
                        formEl.resetFields()
                        router.replace({ name: 'UserLists' })
                    } else {
                        notification.warning('User', result.response.data.data.error)
                    }
                    loading.value = false
                } else {
                    firstError.value = Object.keys(fields)[0]
                    loading.value = false
                    userForm.value.focus()
                }
            })
        }

         const user = computed(() => {
            return store.state.user.user
        })

        const confirm_password = (rule: any, value: any, callback: any) => {
            if(!value && Validations.password_required) {
                callback(new Error('Please enter confirm password'))
            } else if(user.value.password !== value) {
                callback(new Error('Password and confirm password do not match'))
            } else {
                callback()
            }
        }

        const rules = reactive({
            name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
            email: [{ validator: Validations.email, trigger: ['blur', 'change'] }],
            password: [{ validator: Validations.password, trigger: ['blur', 'change'] }],
            password_confirmation: [{ validator: confirm_password, trigger: ['blur', 'change'] }],
            role_id: [{ validator: Validations.role_id, trigger: ['blur', 'change'] }],
            policy_agent_id: [{ validator: Validations.policy_agent_id, trigger: ['blur', 'change'] }],
            team_leader: [{ validator: Validations.team_leader, trigger: ['blur', 'change'] }],
            is_round_robin: [{ validator: Validations.is_round_robin, trigger: ['blur', 'change'] }],
            skip_round: [{ validator: Validations.skip_round, trigger: ['blur', 'change'] }],
            agent_type: [{ validator: Validations.agent_type, trigger: ['blur', 'change'] }],
            renewal_deal: [{ validator: Validations.renewal_deal, trigger: ['blur', 'change'] }],
            assign: [{ validator: Validations.assign, trigger: ['blur', 'change'] }],
            assign_lang: [{ validator: Validations.assign_lang, trigger: ['blur', 'change'] }],
            agents: [{ validator: Validations.agents, trigger: ['blur', 'change'] }],
        })

        onBeforeRouteLeave(() => {
            Users.setUserDefault()
        })

        return {
            user,
            rules,
            loading,
            userForm,
            firstError,
            ruleFormRef,
            submitStore
        }
    },
})
